<template>
  <!-- 销售合同管理 -->
  <div class="salesContractManagement">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :reset="true" :form-inline="formInline" :search-form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button
          size="small"
          type="primary"
          @click="settlementContract"
        >
          合同结算
        </el-button>
      </div>
      <Table
        :radio-show="true"
        :operation-button="operationButton"
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
        @getCheckData="getCheckData"
      >
        <template #expiryContractDate="{row}">
          {{ row.expiryContractDate||'-' }}
          <div v-if="row.isOverdue==='1'" class="overdue-box">
            已逾期
          </div>
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- t同步采购合同弹窗 -->
    <el-dialog title="请关联上游合同" :visible.sync="dialogTableVisible" width="45%" :close-on-click-modal="false">
      <el-form ref="fromInline" class="sales-dialog-form" :model="synchronizingContractData" label-width="120px">
        <el-form-item
          label="上游合同编号"
          prop="supplierContractNo"
          :rules="[...rules.message]"
        >
          <el-input v-model="synchronizingContractData.supplierContractNo" placeholder="请输入上游采购合同编号" :maxlength="30" autocomplete="off" />
        </el-form-item>
        <el-form-item ref="purchaseFileUrl" label="上游合同" prop="purchaseFileUrl" :rules="rules.uploadFile">
          <div class="flex-box">
            <FileUpload file-format="pdf，jpg，png，jpeg" :edit-file-name="synchronizingContractData.purchaseFileName" @getFile="getAgentFile" />
            <el-button v-if="synchronizingContractData.purchaseFileUrl" size="small" type="text" @click="viewFile">
              查看
            </el-button>
          </div>
          <p>文件格式支持pdf，jpg，png，jpeg</p>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="widen-button" size="small" @click="dialogTableVisible = false">
          取 消
        </el-button>
        <el-button class="widen-button" type="primary" :loading="$store.state.loading" size="small" @click="synchronization">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <PdfContract
      :sign-or-not="pdfContractConfig.signOrNot"
      :visible.sync="pdfContractConfig.visible"
      :url="pdfContractConfig.url"
      @confirmSign="confirmSign"
    />
    <!-- 申请用印的弹窗 -->
    <Dialog
      ref="dialog"
      :dialog-width="'460px'"
      :edit-form-data="editFormData"
      :add-and-edit-form-item="addAndEditFormItem"
      @getFormData="getFormData"
    />
  </div>
</template>

<script>
import Dialog from '@/components/Dialog'
import FormSearch from '@/components/FormSearch'
import FileUpload from '@/components/FileUpload'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import PdfContract from '@/components/PdfContract'
import { rules, formPattern } from '@/unit/matchFormRegex'
import { roundUpToInteger } from '@/unit/index'
import { agentSettleGenerateSettlementBill, agentPurchaseContractRefusedSign, deliveryInfoWarehouseCompleted, agentPurchaseContractSignAudit, agentPurchaseContractGetEsignUrl, agentPurchaseContractPage, agentPurchaseContractUpdateStatus, agentPurchaseContractSynPurContract } from '@/http/agentApi/salesContractManagement'
export default {
  components: {
    FormSearch,
    PdfContract,
    FileUpload,
    Table,
    Dialog,
    Pagination
  },
  data() {
    return {
      editFormData: {},
      dialogTableVisible: false,
      rules,
      formPattern,
      synchronizingContractData: {
        purchaseFileUrl: ''
      },
      addAndEditFormItem: [{
        label: '审批类型',
        type: 'radio',
        child: [{ value: 1, label: '系统审批' }, { value: 2, label: '钉钉审批' }],
        value: 'oaType'
      }],
      pdfContractConfig: { visible: false, url: '' },
      formInline: {
        pageSize: 10,
        pageNum: 1,
        contractStatus: ''
      },
      formItemArr: [
        { type: 'input', label: '合同单号', value: 'contractNo', width: '120' },
        { type: 'input', label: '合作企业', value: 'supplierCmpName' },
        { type: 'input', label: '经销商', value: 'custCmpName', width: '120' },
        { type: 'select', label: '合同状态', value: 'contractStatus', width: '120', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('contractStatus') },
        { type: 'radio', label: '是否逾期', value: 'isOverdue', width: '120', pLabel: 'dictName', pValue: 'dictId', child: [{ dictName: '是', dictId: '1' }, { dictName: '否', dictId: '0' }] }
      ],
      itemData: [
        { label: '合同单号', prop: 'contractNo', width: 180 },
        // { label: '采购计划编号', prop: 'planNo', width: 180 },
        { label: '合作企业', prop: 'supplierCmpName', width: 180 },
        { label: '经销商', prop: 'custCmpName', width: 180 },
        { label: '合同总额(元)', prop: 'aggregateAmount', width: 180, type: 'money' },
        { label: '保证金(元)', prop: 'depositPayable', width: 150, type: 'money' },
        // { label: '代采费率', prop: 'agentRate', width: 150 },
        { label: '合同到期日', prop: 'expiryContractDate', width: 120 },
        { label: '创建时间', prop: 'createTime', width: 140 },
        // { label: '付款状态', prop: 'paymentStatus', width: 160, child: this.$store.getters.getDictionaryItem('paymentStatus') },
        { label: '合同状态', prop: 'contractStatus', width: 160, child: this.$store.getters.getDictionaryItem('contractStatus') }
      ],
      listData: [],
      operationButton: [
        { num: 2, val: 'contractStatus', bType: 'primary', label: '签署协议', handleEvent: this.agreementSigning },
        { num: 2, val: 'contractStatus', bType: 'primary', label: '拒绝签署', handleEvent: this.refusedSign },
        { num: 3, val: 'contractStatus', bType: 'primary', label: '关联上游合同', handleEvent: this.simultaneousPurchaseContract },
        { childOne: [{ num: 18, val: 'contractStatus' }, { num: 20, val: 'contractStatus' }, { num: 1, val: 'paymentStatus' }, { num: 3, val: 'paymentStatus' }], bType: 'primary', label: '申请垫资付款', handleEvent: this.matEndowmentPayment },
        // { num: 20, val: 'contractStatus', bType: 'primary', label: '申请垫资付款', handleEvent: this.matEndowmentPayment },
        // { num: 1, val: 'paymentStatus', bType: 'primary', label: '申请垫资付款', handleEvent: this.matEndowmentPayment },
        // { num: 3, val: 'paymentStatus', bType: 'primary', label: '申请垫资付款', handleEvent: this.matEndowmentPayment },
        { num: 7, val: 'contractStatus', bType: 'primary', label: '支付货款', handleEvent: this.viewInfoTable },
        { num: 11, val: 'contractStatus', bType: 'primary', label: '开票', handleEvent: this.viewInfoTable },
        { num: 16, val: 'contractStatus', bType: 'primary', label: '申请审批', handleEvent: this.landUseClaim },
        { bType: 'primary', label: '入库完成', handleEvent: this.warehouseComplete, child: [{ val: 'invStatus' }] },
        { bType: 'primary', label: '查看协议', handleEvent: this.paymentBondTable, childOne: [{ num: 3, val: 'contractStatus' }, { num: 4, val: 'contractStatus' }, { num: 5, val: 'contractStatus' }, { num: 6, val: 'contractStatus' }, { num: 7, val: 'contractStatus' }, { num: 8, val: 'contractStatus' }, { num: 9, val: 'contractStatus' }, { num: 10, val: 'contractStatus' }, { num: 11, val: 'contractStatus' }, { num: 12, val: 'contractStatus' }] },
        // { num: 10, val: 'contractStatus', bType: 'primary', label: '结算', handleEvent: this.agentSettle },
        { bType: 'primary', label: '详情', handleEvent: this.viewInfoTable }
      ],
      loading: false,
      submitId: '',
      total: 0

    }
  },
  watch: {
    '$route': {
      handler(val) {
        if (val.query.contractStatus) {
          this.formInline.contractStatus = val.query.contractStatus
        } else {
          this.formInline.contractStatus = ''
        }
        this.getdata(true)
      }
    }
  },
  methods: {
    settlementContract() {
      if (!this.submitId) {
        this.$message.error('请先选择一个销售合同')
        return
      }
      this.agentSettle({ id: this.submitId })
    },
    // 结算
    agentSettle({ id }) {
      agentSettleGenerateSettlementBill(id, res => {
        this.$message.success('成功')
        if (res.data) {
          this.$router.push({
            path: '/salesContractManagement/additionalSettlement',
            query: { id: res.data }
          })
        }
        this.getdata(true)
      })
    },
    // 获取点击的单选框
    getCheckData(value) {
      this.submitId = value.id
    },
    // 拒绝签署
    async refusedSign(row) {
      const confirm = await this.$confirm(`您的操作将终止此条合同签署，是否继续？`, '提示', { type: 'error' }).catch(err => err)
      if (confirm !== 'confirm') return
      agentPurchaseContractRefusedSign({
        id: row.id,
        contractStatus: row.contractStatus
      }, () => {
        this.$message.success('成功！')
        this.getdata(true)
      })
    },
    // 全部入库
    async warehouseComplete(row) {
      const confirm = await this.$confirm(`确认全部入库后将无法再入库，您要继续吗？`, '提示', { type: 'error' }).catch(err => err)
      if (confirm !== 'confirm') return
      deliveryInfoWarehouseCompleted(row.id, (res) => {
        if (Number(res.data) > 0) {
          this.$alert(`该合同已经入库完成，请线下退还保证金金额：${res.data}元。`, '退保证金通知', {
            confirmButtonText: '我知道了',
            center: true,
            callback: () => {
              this.getdata(true)
            }
          })
          return
        }
        this.$message.success('成功！')
        this.getdata(true)
      })
    },
    // 申请用印
    landUseClaim(row) {
      this.editFormData.id = row.id
      this.editFormData.type = false
      this.$refs.dialog.editFormVisible = true
      this.$refs.dialog.dialogTitle = '申请审批'
    },
    getFormData(value) {
      if (this.editFormData.type) {
        agentPurchaseContractUpdateStatus({ oaType: value.oaType, contractStatus: 5, id: this.editFormData.id }, () => {
          this.$message.success('成功！')
          this.getdata(true)
          this.$refs.dialog.editFormVisible = false
        })
        return
      }
      agentPurchaseContractSignAudit({ oaType: value.oaType, id: this.editFormData.id, contractStatus: 17 }, () => {
        this.$message.success('成功')
        this.getdata(true)
        this.$refs.dialog.editFormVisible = false
      })
    },
    // 确认签署
    confirmSign() {
      agentPurchaseContractGetEsignUrl({ contractId: this.pdfContractConfig.id, operFlat: 2 }
        , res => {
          this.$message.success('成功！')
          this.pdfContractConfig.visible = false
          window.open(res.data)
        })
    },
    // 获取上传的文件名字跟URL
    getAgentFile(file) {
      this.synchronizingContractData.purchaseFileName = file.fileName
      this.synchronizingContractData.purchaseFileUrl = file.fileUrl
      this.$refs['purchaseFileUrl'].clearValidate()
      this.$forceUpdate()
    },
    // 查看文件
    viewFile() {
      window.open(this.$fileUrl + this.synchronizingContractData.purchaseFileUrl)
    },
    // 点击确定按钮  进行同步
    synchronization() {
      this.$refs['fromInline'].validate((valid) => {
        if (valid) {
          if (!this.synchronizingContractData.purchaseFileUrl) {
            this.$message.error('请上传文件')
            return
          }
          agentPurchaseContractSynPurContract(this.synchronizingContractData, () => {
            this.$message.success('成功！')
            this.getdata(true)
            this.dialogTableVisible = false
          })
        }
      })
    },
    // 获取列表
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      agentPurchaseContractPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.aggregateAmount = roundUpToInteger((Number(item.totalPayment) || 0) + (Number(item.estimateCost) || 0))
          if (item.completionStatus === 0 && (item.contractStatus === 6 || item.contractStatus === 10)) {
            item.invStatus = true
            return
          }
          item.invStatus = false
        })
        this.total = res.data.total
        this.submitId = ''
      })
    },
    // 同步采购合同
    async simultaneousPurchaseContract(row) {
      this.dialogTableVisible = true
      this.synchronizingContractData.id = row.id
    },
    // 添加
    addRow() {
      this.$router.push({ path: '/salesContractManagement/addSalesContract', query: { type: true }})
    },
    // 查看协议
    paymentBondTable(row) {
      this.pdfContractConfig = {
        visible: true,
        url: row.contractFileUrl,
        signOrNot: false
      }
    },
    // 垫资付款
    async matEndowmentPayment(row) {
      this.editFormData.id = row.id
      this.editFormData.type = true
      this.$refs.dialog.editFormVisible = true
      this.$refs.dialog.dialogTitle = '申请审批'
    },
    // 协议签署
    agreementSigning(row) {
      this.pdfContractConfig = {
        visible: true,
        url: row.contractFileUrl,
        id: row.id,
        signOrNot: true
      }
    },
    // 详情
    viewInfoTable(row) {
      this.$router.push({ path: '/salesContractManagement/salesContractDetails', query: { rowId: row.id }})
    }
  }
}
</script>
<style lang="scss" scoped>
.salesContractManagement {
  .overdue-box{
    text-align: center;
    line-height: 22px;
    height: 22px;
    width: 54px;
    border-radius: 6px;
    font-size: 12px;
    background-color: $assistColor5;
    color: #fff;
  }
  .sales-dialog-form {
    padding: 0 62px 0 28px;
  }

  .dialog-footer {
    text-align: center;
  }
  .flex-box{
    display: flex;
    align-items: center;
    .el-button{
      margin-left: 18px;
    }
  }
}
</style>
